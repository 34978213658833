import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CircleCommonProvider } from "@maysoft/circle-common-react";
import { CommonComponentProvider, IItemRoute } from "@maysoft/common-component-react";

import "./classes/globals.css";
import "./classes/utilities.css";
import "@maysoft/common-component-react/dist/index.css";

import Constants from "constants/index";
import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "./constants/screens";
import NotFoundScreen from "./screens/notFound";
import DashboardScreen from "screens/dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginRedirectScreen from "screens/login/loginRedirect";

import { RootState } from "store";
import { MainLayout } from "layout";
import { useRenderRoute } from "./routes/routes";
import { useAuth } from "providers/authProvider";
import { useMapScreenName } from "routes/mapScreenName";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IRecordMenuDetail } from "commons/interfaces";

function App() {
    const auth = useAuth();
    const dispatchRedux = useDispatch();

    const dataMapScreenName = useMapScreenName();

    const [renderKey, setRenderKey] = useState(0);

    const routes = useRenderRoute(renderKey);
    const [menus, setMenus] = useState<IItemRoute[]>(routes);

    const [menuDetails, setMenuDetails] = useState<IRecordMenuDetail[]>([]);

    const currentLanguage = Strings.getLanguage();

    const userInfo = useSelector((state: RootState) => state.userInfo);
    const clientId =
        Helpers.getUrlParams(["clientId"])?.clientId || Helpers.getItemInLocalStorage(Constants.StorageKeys.CLIENT_ID, Constants.CLIENT_ID);

    useEffect(() => {
        const setKey = () => {
            setRenderKey(Date.now());
        };

        __EventEmitter.addListener(Constants.EventName.LANGUAGE_CHANGE, setKey);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.LANGUAGE_CHANGE, setKey);
        };
    }, []);

    // const handleCompareTwoArray = (array1: string[], array2: string | string[]) => {
    //     if (Array.isArray(array2)) {
    //         const index = array1.findIndex(element => array2.includes(element));
    //         return (index !== -1);
    //     } else {
    //         return array1.includes(array2);
    //     }
    // };

    // useEffect(() => {
    //     if (auth?.user == null) {
    //         return;
    //     }

    //     let routesTemp: any[] = [];
    //     const resourceMenu = [...userInfo?.resourceMenu || [], Constants.ResourceMenu.DASHBOARD];
    //     if (resourceMenu.length > 0) {
    //         routesTemp = routes.filter((item) => handleCompareTwoArray(resourceMenu, item.resourceCode));
    //         routesTemp.forEach((route, index) => {
    //             if ([...route.subMenu || []].length > 0) {
    //                 const subMenuFilter = [...route.subMenu || []].filter((item: any) => handleCompareTwoArray(resourceMenu, item.resourceCode));
    //                 if (subMenuFilter?.length > 0) {
    //                     const newRoute: any = { ...route };
    //                     newRoute.subMenu = subMenuFilter;
    //                     routesTemp[index] = newRoute
    //                 }
    //             }
    //         });
    //     }

    //     setMenus(routesTemp);

    // }, [auth.user, userInfo?.resourceMenu, routes]);

    useEffect(() => {
        if (auth?.user == null) {
            return;
        } else {
            setMenuDetails([...(userInfo?.menuDetails || [])]);
        }
    }, [auth.user, userInfo?.menuDetails]);

    const renderRoute = useMemo(() => {
        const arrRoute: any[] = [];

        // menus.forEach((item, key) => {
        //     if (item.subMenu?.length > 0) {
        //         item.subMenu.forEach((el, index) => {
        //             arrRoute.push(
        //                 <Route
        //                     path={el.screenPath}
        //                     key={`${key}+${index}}`}
        //                     element={el.screenName ?? <></>}
        //                 />
        //             );
        //         });
        //     } else {
        //         arrRoute.push(
        //             <Route
        //                 key={key}
        //                 path={item.screenPath}
        //                 element={item.screenName ?? <></>}
        //             />
        //         );
        //     }
        // });

        menuDetails.forEach((item, key) => {
            arrRoute.push(<Route key={key} path={item.externalUrl} element={dataMapScreenName[item.screenName] ?? <></>} />);

            if (!Helpers.isNullOrEmpty(item.extraInformation)) {
                const extraInformation = JSON.parse(item.extraInformation);
                if (Array.isArray(extraInformation)) {
                    extraInformation.forEach((el) => {
                        arrRoute.push(
                            <Route
                                key={key}
                                path={el.ScreenPath || el.screenPath}
                                element={dataMapScreenName[el.ScreenName || el.screenName] ?? <></>}
                            />
                        );
                    });
                } else {
                    arrRoute.push(
                        <Route
                            key={key}
                            path={extraInformation?.ScreenPath || extraInformation?.screenPath}
                            element={dataMapScreenName[extraInformation?.ScreenName || extraInformation?.screenName] ?? <></>}
                        />
                    );
                }
            }
        });

        return arrRoute;
    }, [dataMapScreenName, menuDetails]);

    const renderMainLayout = useMemo(() => <MainLayout routes={menus} menuDetail={menuDetails} />, [menus, menuDetails]);

    const listMultiLanguage = useMemo(() => {
        return [
            { code: Constants.LanguageContent.VI, name: Strings.Languages.VI },
            { code: Constants.LanguageContent.EN, name: Strings.Languages.EN },
        ];
    }, [Strings.getLanguage()]);

    return (
        <CommonComponentProvider
            value={{
                axios: axios as any,
                userInfo: {
                    listGroup: userInfo?.listGroup,
                    userProfile: userInfo?.userProfile,
                    resourceCodes: userInfo?.resourceCodes,
                    listOrganization: userInfo?.listOrganization,
                },
                language: currentLanguage,
                listMultiLanguage: listMultiLanguage,
                clientId: clientId || Constants.CLIENT_ID,
                tenantCode: Constants.TENANT_CODE.IDENTITY,
                serviceCode: Constants.SERVICE_CODE.IDENTITY,
                organizationId: userInfo?.currentOrganization,

                dataMapScreenName: dataMapScreenName,

                onShowLoading() {
                    dispatchRedux(showLoading(true));
                },
                onHideLoading() {
                    dispatchRedux(showLoading(false));
                },
                onSuccess(msg) {
                    dispatchRedux(setDataAlert({ message: msg, type: "success" }));
                },
                onError(msg) {
                    dispatchRedux(showLoading(false));
                    dispatchRedux(setDataAlert({ message: msg, type: "error" }));
                },
            }}
        >
            <CircleCommonProvider>
                <BrowserRouter key={renderKey}>
                    <Routes>
                        <Route path={Screens.LOGIN_REDIRECT} element={<LoginRedirectScreen />} />
                        <Route path={Screens.HOME} element={<ProtectedRoute>{renderMainLayout}</ProtectedRoute>}>
                            <Route index element={<DashboardScreen />} />
                            {renderRoute}
                            <Route path="*" element={<NotFoundScreen />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </CircleCommonProvider>
        </CommonComponentProvider>
    );
}

export default App;
