import { Theme } from "@mui/material";

const clientItem = (theme: Theme, ownerState: { index: number; length: number; line: number; checked: boolean; collapse?: boolean }) => {
    const { index, length, line, checked, collapse = false } = ownerState;
    const lineColor = checked !== true ? "#bebebe" : "#1a73e8";
    return {
        marginLeft: "18px",
        // borderLeft: `1px solid ${index < length ? lineColor : "transparent"}`,
        position: "relative",
        "&::after": {
            content: '""',
            position: "absolute",
            width: "1%",
            height: "1px",
            backgroundColor: !checked ? "#bebebe" : lineColor,
            top: "19px",
            left: 0,
        },
        "&::before": {
            content: '""',
            position: "absolute",
            width: "1px",
            height: collapse && index === length ? "20px" : index === length ? "51%" : "100%",
            backgroundColor: lineColor,
            top: 0,
            left: "-1px",
        },
    };
};

export { clientItem };
